<template>
    <v-container class="pb-10 mt-3">
        <v-row no-gutters v-if="!loading" id="top">
          <v-col cols="12" lg="7" v-if="course">
            <img 
                :src="course.image ? course.image.url : require('@/assets/images/landing/background/course-photo.png')" 
                v-on:error="require('@/assets/images/landing/background/course-photo.png')"
                class="rounded-lg"
                height="300"
                width="100%"
            />
            <CourseDetails :course="course"/>
          </v-col>
          <v-spacer />
          <v-col cols="12" lg="4" v-if="course">
            <CourseTimeline :course="course"/>
          </v-col>
        </v-row>
        
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="l-primary"
            rounded
            height="8"
            class="mx-auto"
        ></v-progress-linear>

        <div class="primary-font f18 fw600 mt-5 mb-2" v-if="courses.length > 0">
            More Courses
            <v-slide-group
                show-arrows
            >
                <v-slide-item v-for="course in courses" :key="course.id">
                    <GalleryCards class="ma-1" :course="course" />
                </v-slide-item>
            </v-slide-group>
        </div>

    </v-container>
</template>

<style scoped>
.v-progress-linear {
    width: 180px !important;
    margin-top: 25vh !important;
}
</style>

<script>

import { mapActions, mapState } from "vuex"
import CourseDetails from '@/components/landing/courses/course-details.vue';
import CourseTimeline from "@/components/landing/courses/course-timeline.vue";
import GalleryCards from "@/components/landing/cards/gallery-card.vue";

export default {
    name: 'landing-home',
    components: {
    CourseTimeline,
    CourseDetails,
    GalleryCards
},
    data: () => ({
        loading: false
    }),
    mounted() {
        this.loading = true
        this.getTenantCourseAction(this.$route.params.uuid).then(() => {
            this.loading = false
        })
    }, 
    computed: {
        ...mapState({
            course: (state) => state.course,
            courses(state) {
                return [...state.free_courses, ...state.paid_courses].filter(item => item.uuid !== this.$route.params.uuid)
            }
        }),
    },
    methods: {
        ...mapActions(['getTenantCourseAction']),

        setAltImg(event) { 
            event.target.src = "../assets/images/background/course-photo.png" 
        } 
    },
    watch: {
        '$route'( val ){
            this.$vuetify.goTo('#top')
            this.loading = true
            this.getTenantCourseAction(val.params.uuid).then(() => {
                this.loading = false
            })
        }
    }
}
</script>